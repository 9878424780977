import React, { useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import tw from 'twin.macro';

const GithubButton = tw.a`
bg-button rounded-md py-2 px-4 cursor-not-allowed font-bold text-base text-primary focus:outline-none mb-4
`;

const GitHub: React.FC = () => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  return (
    <Popover
      onClickOutside={() => setPopoverOpen(false)}
      isOpen={isPopoverOpen}
      positions={['bottom', 'top', 'right', 'left']}
      align={'start'}
      // preferred positions by priority
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          style={{ padding: 0, paddingTop: '10px', paddingBottom: '10px' }}
          arrowColor={'grey'}
          arrowSize={10}
          arrowStyle={{
            borderBottomColor: 'var(--color-bg-button)',
          }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div
            className="bg-button rounded-md py-4 px-4 text-sm text-primary"
            style={{ maxWidth: '250px' }}
            onClick={() => setPopoverOpen(!isPopoverOpen)}
          >
            Zdrojový kód není veřejně přístupný z důvodu smlouvy NDA. Pro
            získání přístupu mne kontaktujte.
          </div>
        </ArrowContainer>
      )}
    >
      <GithubButton
        aria-label="Otevřít github"
        onClick={() => setPopoverOpen(true)}
      >
        GitHub
      </GithubButton>
    </Popover>
  );
};
export default GitHub;
